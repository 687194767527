<template>
  <div class="main-nav">
    <p>
      经常作图&nbsp;&nbsp;&nbsp;可按&nbsp;&nbsp;
      <span class="main-navs">Ctrl</span>
      +
      <span class="main-navs">D</span>
      &nbsp;&nbsp;将本站添加至收藏夹
    </p>
  </div>
</template>

<script>
</script>

<style lang="less">
// 子元素被父元素包裹，方便区分上下级关系
.main-nav {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 60px;
  font-size: 16px;
  //   font-weight: 400;  500一下不用写
  color: #555;
  line-height: 60px;
  text-align: center;
  letter-spacing: 2px;
  background: #fafafa;
  .main-navs {
    padding: 8px 7px;
    color: #f64;
    line-height: 12px;
    border-radius: 4px;
    letter-spacing: 0;
    display: inline-block;
    background: #fde9e4;
  }
}
</style>